/* eslint-disable */
import { RouterLink } from "vue-router";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ApplicationBar",
  data: () => ({
    appBarBrand: process.env.VUE_APP_BRAND, 
    logosrc: process.env.VUE_APP_LOGO,
    screenwidth: 0,
    navigationDrawer: true,
    fab: false,
    open: ["memberregistration"],
    profilling: [
      {
        title: "Patient List",
        link: "/HealthScreeningAndAssessment",
        name: "HealthScreeningAndAssessment",
      },
      {
        title: "Patient Information",
        link: "/PatientProfile",
        name: "PatientProfile",
      },
      {
        title: "Medical & Surgical History",
        link: "/MedicalSurgicalHistory",
        name: "MedicalSurgicalHistory",
      },
      {
        title: "Family & Personal History",
        link: "/FamilyPersonalHistory",
        name: "FamilyPersonalHistory",
      },
      {
        title: "Laboratory Imaging Results",
        link: "/LaboratoryImagingResults",
        name: "LaboratoryImagingResults",
      },
      { title: "Immunizations", link: "Immunizations", name: "Immunizations" },
      {
        title: "OB-Gyne History",
        link: "/OBGyneHistory",
        name: "OBGyneHistory",
      },
      {
        title: "Pertinent Physical Examination Findings",
        link: "/PertinentPhysicalExaminationFindings",
        name: "PertinentPhysicalExaminationFindings",
      },
      {
        title: "NCD High-Risk Assessment",
        link: "/NCDHighRiskAssessment",
        name: "NCDHighRiskAssessment",
      },
    ],
    consultation: [
      { title: "Patient List", link: "/Consultation", name: "Consultation" },
      {
        title: "Patient Information",
        link: "/ConsClientInformation",
        name: "ConsClientInformation",
      },
      {
        title: "Subjective/History of Illness",
        link: "/ConsSubjectiveHistoryOfIlleness",
        name: "ConsSubjectiveHistoryOfIlleness",
      },
      {
        title: "Objective/Physical Examination",
        link: "/ConsPhysicalExaminationFindings",
        name: "ConsPhysicalExaminationFindings",
      },
      {
        title: "Assessment and Diagnosis",
        link: "/ConsAssessmentAndDiagnosisResult",
        name: "ConsAssessmentAndDiagnosisResult",
      },
      {
        title: "Plan/Management",
        link: "/PlanManagement",
        name: "PlanManagement",
      },
      {
        title: "Dispensing Medicine",
        link: "/ConsMedicineDispense",
        name: "ConsMedicineDispense",
      },
    ],
    reports: [
      { title: "E-Press", link: "/Eprescription", name: "Eprescription" },
      { title: "EKAS", link: "Ekonsavslip", name: "Ekonsavslip" },
    ],
    
    routeName: "",
  }),
  computed: {
    ...mapGetters(["getAuthStatus", "getCurrentUser", "getCurrentRouteName"]),
  },
  watch: {
    getCurrentRouteName(newValue, oldValue) {
      const routeName = newValue;
      this.getActiveStyle([routeName]);
      if (this.profilling.filter((d) => d.name === routeName).length > 0) {
        this.open = ["profilling"];
      } else if (
        this.consultation.filter((d) => d.name === routeName).length > 0
      ) {
        this.open = ["consultation"];
      } else if (this.reports.filter((d) => d.name === routeName).length > 0) {
        this.open = ["reports"];
      }
    },
  },
  mounted() {
    /*
        this.checkAuth()
            .then((resp) => {
             
           
        })
            .catch((err) => {
        });
        */
    /*
        this.$router.isReady().then(() => {
            const routeName = this.$router.currentRoute.value.name;
             if(this.profilling.filter((d) => d.name === routeName).length > 0){
                this.open = ['profilling'];
             }else if(this.consultation.filter((d) => d.name === routeName).length > 0){
                this.open = ['consultation'];
             }else if(this.reports.filter((d) => d.name === routeName).length > 0){
                this.open = ['reports'];
             }
          
        });
      */
  },

  methods: {
    ...mapActions(["checkAuth", "signOut", "setCurrentRouteName"]),
    isActive(route) {
      return route.includes(this.$route.name);
    },
    goTo(route) {
      this.$router.push(route);
    },
    showNavigationDrawer() {
      this.navigationDrawer = !this.navigationDrawer;
    },
    getActiveStyle(route) {
      return this.isActive(route)
        ? { backgroundColor: "#e3ecf7", color: "#1867c0" } // Active color style
        : { backgroundColor: "#f7fafc", color: " #000000" };
    },
    logOut(e) {
      e.preventDefault();
      this.signOut()
        .then((resp) => {
          this.$router.push({ name: "home" });
        })
        .catch((err) => {
          this.$router.push({ name: "home" });
        });
    },
  },
};
