<template>
	<v-app-bar :elevation="0" color="#f7fafc" v-if="getAuthStatus">
		<template v-slot:prepend>
			<v-app-bar-nav-icon @click="showNavigationDrawer()"></v-app-bar-nav-icon>
		
			
		</template>
		
		
		<v-app-bar-title>
			<div class="mr-2" style="float: left;">
				<v-img :src="logosrc" width="50" ></v-img>
			</div>
			<div class="my-2" style="float: left;">
				{{ appBarBrand }}
			</div>
			
		</v-app-bar-title>

		<template v-slot:append>
			<!--v-btn icon="mdi-heart"></v-btn>

			<v-btn icon="mdi-magnify"></v-btn>

			<v-btn icon="mdi-dots-vertical"></v-btn-->
			<v-menu min-width="200px" rounded >
				<template v-slot:activator="{ props }">
					<v-btn icon v-bind="props">
						<v-avatar color="brown" size="large">
							<span class="text-h5">{{ getCurrentUser.HOSP_NAME.split(' ').slice(0, 2).map(word => word.charAt(0)).join('') }}</span>
						</v-avatar>
					</v-btn>
				</template>
				<v-card>
					<v-card-text>
						<div class="mx-auto text-center">
							<v-avatar color="brown">
								<span class="text-h5">{{ getCurrentUser.HOSP_NAME.split(' ').slice(0, 2).map(word => word.charAt(0)).join('') }}</span>
							</v-avatar>
							<h3>{{ getCurrentUser.HOSP_NAME }}</h3>
							<!--p class="text-caption mt-1">
							Accreditation No. {{ getCurrentUser.ACCRE_NO }}
							</p-->
							<v-divider class="my-3"></v-divider>
							<v-btn variant="text" rounded @click="logOut">
								Log-Out
							</v-btn>
							<!--v-divider class="my-3"></v-divider>
							<v-btn variant="text" rounded>
								Disconnect
							</v-btn-->
						</div>
					</v-card-text>
				</v-card>
			</v-menu>
		</template>
	</v-app-bar>

	<v-navigation-drawer :elevation="0" v-model="navigationDrawer" floating color="#f7fafc" v-if="getAuthStatus">
		<v-list v-model:opened="open">

			<v-list-item prepend-icon="mdi-account-plus-outline" title="Member Registration" rounded="shaped"
				:style="getActiveStyle(['memberregistration', 'AddEditMemberRegistration'])"
				:class="{ 'v-list-item--active': isActive(['memberregistration', 'AddEditMemberRegistration']) }"
				value="memberregistration" @click="goTo('memberregistration')"></v-list-item>



			<v-list-group value="profilling" rounded="shaped">
				<template v-slot:activator="{ props }">
					<v-list-item v-bind="props" prepend-icon="mdi-badge-account-horizontal-outline"
						title="Profiling"></v-list-item>
				</template>
				<v-divider></v-divider>
				<v-tooltip v-for="(item, i) in profilling" :key="i" :text="item.title" origin="auto">
					<template v-slot:activator="{ props }">
						
						<v-list-item class="custom-v-list-item" rounded="shaped" v-bind="props" :value="item.name"
						:style="getActiveStyle([item.name])"
						:class="{ 'v-list-item--active': isActive([item.name]) }" style="padding: 0px !important;" @click="goTo(item.name)">
							<template #prepend>
								<v-icon
									style="margin-left: 25px !important; margin-right: 0px !important;">mdi-arrow-right-thin</v-icon>
							</template>
							<v-list-item-title style="margin-left: 0px !important;margin-left: 0px !important;">{{
								item.title
								}}</v-list-item-title>
						</v-list-item>
					</template>
				</v-tooltip>
				<v-divider></v-divider>
			</v-list-group>

			<v-list-group value="consultation" rounded="shaped">
				<template v-slot:activator="{ props }">
					<v-list-item v-bind="props" prepend-icon="mdi-medical-bag" title="Consultation"></v-list-item>
				</template>
				<v-divider></v-divider>
				<v-tooltip v-for="(item, i) in consultation" :key="i" :text="item.title" origin="auto">
					<template v-slot:activator="{ props }">
						<v-list-item class="custom-v-list-item" rounded="shaped" v-bind="props" :value="item.name"
						:style="getActiveStyle([item.name])"
						:class="{ 'v-list-item--active': isActive([item.name]) }" style="padding: 0px !important;" @click="goTo(item.name)">
							<template #prepend>
								<v-icon
									style="margin-left: 25px !important; margin-right: 0px !important;">mdi-arrow-right-thin</v-icon>
							</template>
							<v-list-item-title style="margin-left: 0px !important;margin-left: 0px !important;">{{
								item.title
								}}</v-list-item-title>
						</v-list-item>
					</template>
				</v-tooltip>
				<v-divider></v-divider>
			</v-list-group>

			<v-list-group value="reports" rounded="shaped">
				<template v-slot:activator="{ props }">
					<v-list-item v-bind="props" prepend-icon="mdi-file-document-check-outline"
						title="Reports"></v-list-item>
				</template>
				<v-divider></v-divider>
				<v-tooltip v-for="(item, i) in reports" :key="i" :text="item.title" origin="auto">
					<template v-slot:activator="{ props }">

						<v-list-item class="custom-v-list-item" rounded="shaped" v-bind="props" :value="item.name"
						:style="getActiveStyle([item.name])"
						:class="{ 'v-list-item--active': isActive([item.name]) }" style="padding: 0px !important;" @click="goTo(item.name)">
							<template #prepend>
								<v-icon
									style="margin-left: 25px !important; margin-right: 0px !important;">mdi-arrow-right-thin</v-icon>
							</template>
							<v-list-item-title style="margin-left: 0px !important;margin-left: 0px !important;">{{
								item.title
								}}</v-list-item-title>
						</v-list-item>
					</template>
				</v-tooltip>
				<v-divider></v-divider>
			</v-list-group>

			<v-list-item prepend-icon="mdi-doctor" title="Doctors" to="/Doctorslist" value="Doctorslist"
				rounded="shaped" :style="getActiveStyle(['Doctorslist'])"></v-list-item>

			<v-list-item prepend-icon="mdi-shield-account-outline" title="Data Privacy" to="/DataPrivacy"
				value="DataPrivacy" rounded="shaped" :style="getActiveStyle(['DataPrivacy'])"></v-list-item>
		</v-list>
	</v-navigation-drawer>

</template>

<script>
import ApplicationBar from './ApplicationBar';

export default ApplicationBar;
</script>

<style>
.custom-v-list-item .v-list-item__prepend .v-list-item__spacer {
	width: 5px !important;
}
</style>
