<template>





  <v-app class="rounded rounded-md">
    <ApplicationBar ref="ApplicationBar" />

    <v-main style="min-height: 300px; background-color: #f7fafc;">
      <v-container fluid>
        <router-view v-slot="{ Component }">
          <!--v-expand-transition>
            <component :is="Component" />
          </v-expand-transition-->
          <transition name="scale-transition">
            <component :is="Component" />
          </transition>
        </router-view>
     </v-container>

    </v-main>
    <v-btn v-scroll="onScroll" icon="mdi-chevron-up" v-show="fab" class="backToTopBtn" color="primary"
          @click="toTop">

        </v-btn>
    <MySnackBar ref="MySnackBar" />

  </v-app>
</template>

<script>
/* eslint-disable */
//import './assets/plugins/fontawesome/js/all.min.js'
import { mapActions, mapGetters } from "vuex";
import './assets/css/portal.css'
//import 'bootstrap';
//import './assets/plugins/popper.min.js'
//import './assets/plugins/bootstrap/js/bootstrap.min.js'
//import './assets/js/app.js'
import MySnackBar from "./components/Utils/MySnackBar.vue";
import ApplicationBar from './components/Navigation/ApplicationBar.vue'
export default {
  name: 'App',
  components: {
    ApplicationBar,
    MySnackBar

  },
  data: () => ({
    //
    navigationDrawer: true,
    fab: false,
   
  }),
  computed: {
    ...mapGetters(["getAuthStatus", "getCurrentUser"]),
  },
  created() {
    this.checkAuth().then((resp) => {

    }).catch((err) => {

    })
  },
  methods: {
    ...mapActions(["checkAuth"]),

    onScroll(e) {

      if (typeof window === 'undefined') return
      const top = window.scrollY || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.slowScrollToTop()

    },
    slowScrollToTop() {
      var currentScroll = window.scrollY;
      function scrollStep() {


        if (currentScroll > 0) {
          let scrollSpeed = currentScroll * 0.1
          if (scrollSpeed < 5) {
            scrollSpeed = 5
          }
          window.scroll(0, currentScroll);
          currentScroll = currentScroll - scrollSpeed; // Adjust the scrolling speed here
          requestAnimationFrame(scrollStep);

        }
      }

      scrollStep();
    }

  }

}
</script>

<style scope>
body {
  background-color: #f5f6fe;
}

.app-content {
  padding-top: 5rem;

}

.backToTopBtn {

  position: fixed;
  bottom: 100px;
  right: 5px;
  cursor: pointer;
}
</style>